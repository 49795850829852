import React from 'react'

import { rhythm } from '../utils/typography'
import { colors, presets } from '../utils/theme'
import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import CustomLink from '../components/CustomLink'

const styles = {
  phoneNumber: {
    fontSize: '1.8em',
    textAlign: 'center',
    color: 'black',
    alignSelf: 'stretch',
    width: '100%',
    display: 'block',
    textDecoration: 'none',
  },
  address: {
    fontSize: rhythm(3 / 4),
    marginTop: rhythm(1 / 4),
    fontWeight: 100,
    textAlign: 'center',
    color: 'black',
    alignSelf: 'stretch',
    width: '100%',
    display: 'block',
    marginBottom: rhythm(1 / 2),
    [presets.Desktop]: {
      fontSize: rhythm(3 / 4),
    },
    textDecoration: 'none',
  },
  outer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    flexWrap: 'wrap-reverse',
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
    minWidth: '20%',
    minWidth: '300px',
    backgroundColor: colors.brightBlue,
    alignSelf: 'stretch',
    minHeight: '400px',
    padding: '30px',
    paddingTop: '0px',
    flexDirection: 'column',
    position: 'relative',
    color: 'white',
    paddingBottom: '120px',
    justifyContent: 'space-between',
  },
  youAreHereContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  youAreHere: {
    marginBottom: rhythm(1),
    fontSize: rhythm(1),
    lineHeight: rhythm(1.2),
    textAlign: 'center',
    alignSelf: 'stretch',
  },
  ul: {
    listStyle: 'none',
    listStylePosition: 'outside',
    position: 'relative',
    margin: '0 auto',
    maxWidth: '100vw',
  },
  li: {
    fontSize: rhythm(1),
    paddingTop: rhythm(2 / 3),
    lineHeight: '1em',
    fontWeight: '100',
    ':before': {
      position: 'absolute',
      left: '0px',
      content: '✓',
      color: 'white',
      fontSize: rhythm(1.2),
    },
  },
  requestAppointment: {
    textDecoration: 'none',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '90px',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.brightBlue,
    textAlign: 'center',
    maxWidth: '100vw',
    fontSize: rhythm(3 / 2),
  },
  rightContainer: {
    marginTop: `-${rhythm(6)}`,
    display: 'flex',
    flex: 3,
    flexGrow: 3,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    [presets.Phablet]: {
      minWidth: '500px',
    },
    [presets.Tablet]: {
      minWidth: '800px',
    },
  },

  smileWithConfidence: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    height: '90px',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    letterSpacing: '12px',
    maxWidth: '100vw',
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontWeight: '100',
    fontSize: rhythm(5 / 4),
  },
  liLink: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: '100',
  },
}

const HomePagePhoto = ({ ...props }) => {
  return (
    <div id="home-page-photo-hpp-div" css={styles.outer}>
      <div id="hpp-div-left-container" css={styles.leftContainer}>
        <div id="hpp-div-left-phone-and-address" css={{ width: '100%' }}>
          <div
            id="hpp-div-left-phone"
            css={{ width: '100%', marginTop: '10px' }}
          >
            <CustomLink css={styles.phoneNumber} href="tel:15072585400">
              507.258.5400
            </CustomLink>
          </div>
          <div id="hpp-div-left-address" css={{ width: '100%' }}>
            <CustomLink
              css={styles.address}
              href="https://www.google.com/maps/place/Collins+Orthodontics/@44.0439713,-92.5086621,17z/data=!3m1!4b1!4m5!3m4!1s0x87f75f3a3b858b8d:0x8e84565903958471!8m2!3d44.0439713!4d-92.5064734"
            >
              2946 Jeremiah Ln NW, Rochester, MN 55901
            </CustomLink>
          </div>
        </div>
        <div
          id="hpp-div-you-are-here-container"
          css={styles.youAreHereContainer}
        >
          <div
            id="hpp-div-you-are-here-div"
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              justifyContent: 'flex-end',
            }}
          >
            <div id="hpp-div-you-are-here-header" css={styles.youAreHere}>
              YOU'RE HERE BECAUSE...
            </div>
            <ul id="hpp-div-you-are-here-list" css={styles.ul}>
              <li id="hpp-div-you-are-here-list-item-braces" css={styles.li}>
                <CustomLink css={styles.liLink} to="/orthodontics/braces">
                  You Want To Learn About Braces & Orthodontics
                </CustomLink>
              </li>
              <li
                id="hpp-div-you-are-here-list-item-meet-dr-collins"
                css={styles.li}
              >
                <CustomLink
                  css={styles.liLink}
                  to="/why-collins/meet-dr-collins"
                >
                  You Are Looking For A Rochester Orthodontist
                </CustomLink>
              </li>
              <li
                id="hpp-div-you-are-here-list-item-schedule-a-free-consult-1"
                css={styles.li}
              >
                <CustomLink
                  css={styles.liLink}
                  to="/the-next-step/schedule-a-free-consult"
                >
                  You Were Referred To Collins Orthodontics
                </CustomLink>
              </li>
              <li
                id="hpp-div-you-are-here-list-item-schedule-a-free-consult-2"
                css={styles.li}
              >
                <CustomLink
                  css={styles.liLink}
                  to="/the-next-step/schedule-a-free-consult"
                >
                  You Want A Second Opinion
                </CustomLink>
              </li>
            </ul>
          </div>
        </div>
        <CustomLink
          id="hpp-div-you-are-here-req-appt-link"
          css={styles.requestAppointment}
          to="/the-next-step/schedule-a-free-consult"
        >
          Request Appointment
        </CustomLink>
      </div>
      <div css={styles.rightContainer}>
        <div
          css={{
            width: '100%',
            height: '100%',
            minHeight: '600px',
            backgroundImage: `url(${props.responsiveImage.images.fallback.src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            css={{
              width: '100%',
              height: '100%',
              minHeight: '600px',
              backgroundImage: `url(${props.responsiveImage.images.fallback.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div css={styles.smileWithConfidence}>
          <span css={{ whiteSpace: 'nowrap' }}>SMILE WITH</span>
          &nbsp;
          <span css={{ color: colors.brightBlue }}>CONFIDENCE</span>
        </div>
      </div>
    </div>
  )
}

const HomePagePhotoWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query HomePageQuery {
        mainPhoto: file(relativePath: { eq: "BackgroundPic_Extended.jpg" }) {
          ...fluidImage1000Sized
        }
      }
    `}
    render={(data) => (
      <HomePagePhoto
        {...props}
        responsiveImage={_.get(
          data,
          'mainPhoto.childImageSharp.gatsbyImageData'
        )}
      />
    )}
  />
)

export default HomePagePhotoWithQuery
