import React from 'react'
import { rhythm } from '../utils/typography'
import { colors, metrics, presets } from '../utils/theme'
import InfusionsoftForm from '../components/InfusionsoftForm'

import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import '../styles/form.css'
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '20px',
    
  },
  reportThumbnail: {
    width: '100%',
    maxWidth: '200px',
    margin: 0,
    [presets.Hd]: {
      maxWidth: '300px',
    },
  },
  freeReportImage: {
    width: '100%',
    maxWidth: '150px',
  },
  form: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    align: 'center',
  },
  input: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: '0',
    overflow: 'visible',
    padding: '.5rem',
    backgroundColor: '#fff',
    border: '1px solid #a8a8a8',
    boxSsizing: 'border-box',
    transition: 'border-color .2s cubic-bezier(.175,.885,.32,1.276)',
    borderRadius: '2px',
    minWidth: '200px',
  },
  required: {
    content: '*',
    color: '#e4002b',
    marginLeft: 'clamp(.69rem,calc(.66rem + .12vw),.75rem)',
    fontWeight: '600',
  },
  label: {
    fontSize: rhythm(3 / 4),
    color: '#414141',
    fontWeight: '600',
    marginTop: '10px',
  },
  button: {
    marginTop: rhythm(1),
    fontSize: rhythm(3 / 4),
    padding: rhythm(1 / 2),
    borderRadius: '5px',
    backgroundColor: colors.lightGrey,
    cursor: 'pointer',
  },
}

class FreeReportWidget extends React.Component {
  render() {
    const a10Things3DCover = getImage(this.props.data.a10Things3DCover)
    const signUpForFreeReport = getImage(this.props.data.SignUpForFreeReport)

    return (
      <div css={styles.container}>
        <div
          css={{
            marginRight: '30px',
            [presets.Desktop]: { marginRight: '0px' },
          }}
        >
          <GatsbyImage
            image={a10Things3DCover}
            css={styles.reportThumbnail}
            alt="10 things 3D cover"
            key="a10Things3DCover"
          />
          <p css={{ paddingLeft: '15px', lineHeight: '1.4rem' }}>
            Sign up for your <strong>FREE report</strong> on the Top Ten Things
            You <strong>Must Know</strong> before choosing your family's
            orthodontist.
          </p>
        </div>
        <div>
          <GatsbyImage
            image={signUpForFreeReport}
            css={styles.freeReportImage}
            alt="Signup for free report image"
            key="signUpForFreeReport"
          />
          <InfusionsoftForm css={styles.form} configKey="tenThings">
            <label css={styles.label} className="required">
              First Name
            </label>
            <input
              css={styles.input}
              id="inf_field_FirstName"
              name="inf_field_FirstName"
              placeholder="First Name"
              type="text"
              required
            />
            {/* <div className="context-message error">First name is required</div> */}
            <label css={styles.label} className="required">
              Email
            </label>
            <input
              css={styles.input}
              id="inf_field_Email"
              name="inf_field_Email"
              placeholder="Email"
              type="text"
              required
            />
            {/* <div className="context-message error">Email is required</div> */}
            <button
              css={styles.button}
              className="infusion-recaptcha"
              id="recaptcha_c612f05dbec6e61322cafca5186b5b70"
              type="submit"
            >
              Submit
            </button>
          </InfusionsoftForm>
        </div>
      </div>
    )
  }
}

const ReportWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query ReportQuery {
        SignUpForFreeReport: file(
          relativePath: { eq: "SignUpForFreeReport.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 150, layout: CONSTRAINED)
          }
        }
        a10Things3DCover: file(relativePath: { eq: "10Things3DCover.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => <FreeReportWidget {...props} data={data} />}
  />
)

export default ReportWithQuery
